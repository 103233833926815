export const GoogleTagManagerBody = ({
	gtmId,
	shouldRender,
}: {
	gtmId: string;
	shouldRender: boolean;
}) => {
	if (!shouldRender) {
		return null;
	}

	return (
		<>
			<noscript
				dangerouslySetInnerHTML={{
					__html: `
					<iframe
						src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
						height="0"
						width="0"
						style="display:none;visibility:hidden"
					></iframe>
				`,
				}}
			/>
		</>
	);
};
