import { useEffect } from 'react';

import { type Repub } from '@cx-modules/constants';
import { type Country, type StateCode } from '@cx-modules/types';

export const GoogleTagManagerHead = ({
	country,
	countryCode,
	gtmId,
	repub,
	shouldRender,
	state,
	userGroup,
	userId,
}: {
	country: string;
	countryCode: Country;
	gtmId: string;
	repub: Repub;
	shouldRender: boolean;
	state: StateCode | '';
	userGroup: string;
	userId: string;
}) => {
	useEffect(() => {
		if (shouldRender && gtmId) {
			const gtmScript = document.createElement('script');

			gtmScript.innerHTML = `
					(function(w, d, s, l, i) {
						w[l] = w[l] || [];
						w[l].push({
								'gtm.start': new Date().getTime(),
								event: 'gtm.js'
						});
						var f = d.getElementsByTagName(s)[0],
								j = d.createElement(s),
								dl = l != 'dataLayer' ? '&l=' + l : '';
						j.async = true;
						j.src =
								'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
						f.parentNode.insertBefore(j, f);
					})(window, document, 'script', 'dataLayer', '${gtmId}');`;

			document.head.appendChild(gtmScript);

			return () => {
				if (document.head.contains(gtmScript)) {
					document.head.removeChild(gtmScript);
				}
			};
		}
	}, [gtmId, shouldRender]);

	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `
							window.dataLayer = window.dataLayer || [];
							dataLayer.push({
								country: '${country}',
								countryCode: '${countryCode}',
								repub: '${repub}',
								state: '${state ?? ''}',
								userId: '${userId}',
								userGroup: '${userGroup}',
							});
						`,
				}}
				id="gtag-init"
			/>
		</>
	);
};
