import {
  useLocation,
  useRouteError,
  useRouteLoaderData } from
'@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

import { REPUBS } from '@cx-modules/constants';

import Logo from '~/components/Logo';
import ocTheme from '~/themes/OC-theme.css';
import usTheme from '~/themes/US-theme.css';

import { getRepubFromURL } from './utils/getRepubFromURL';
import { Favicon, Fonts } from './head';
import { type loader } from './root-loader';

export function RootErrorBoundary() {
  const error = useRouteError();
  let exception;
  if (!error) {
    exception = new Error('Unknown error caught by RootErrorBoundary');
  } else if (error instanceof Error) {
    exception = error;
  } else {
    const err = error as any;
    exception = err.error || err.message || err.originalError || err;
  }
  captureRemixErrorBoundaryError(exception);

  const rootLoaderData = useRouteLoaderData<typeof loader>('root')!;
  const location = useLocation();
  const url = new URL(`https://www.oddschecker.com${location.pathname}`);
  const repub = getRepubFromURL(url);
  const baseUrl = `/${repub === REPUBS.OC.name ? '' : repub.toLowerCase()}`;

  return (
    <html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta content="width=device-width,initial-scale=1" name="viewport" />
				<Favicon
          repub={repub}
          resourcesUrl={rootLoaderData?.resourcesUrl ?? ''} />

				<Fonts url={url.toString()} />
				{/* Local */}
				{[repub === REPUBS.OC.name ? ocTheme : usTheme].map((href) => [
        <link as="style" href={href} key={`${href}-preload`} rel="preload" />,
        <link href={href} key={`${href}-stylesheet`} rel="stylesheet" />]
        )}
				<style>{`
            body,
            input {
                font-family: "Public Sans", sans-serif;
                background-color: var(--ogm-timberwolves);
                display: flex;
                max-width: 1250px;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100vh;
            }

            p {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-family: "Public Sans", sans-serif;
            }

            code,
            kbd,
            pre {
                font-family: "Public Sans", sans-serif;
            }

            .mobileImage {
                display: none;
            }

            .textSection {
                padding: 20px;
            }

            .homepage {
                background-color: var(--ogm-seahawks);
                color: var(--ogm-timberwolves);
                border: none;
            }

            .homepage:hover {
                color: var(--ogm-white);
                background-color: var(--ogm-bucks);
            }

            a {
                display: inline-block;
                border: 1px solid white;
                border-radius: 4px;
                background-color: var(--ogm-timberwolves);
                color: var(--ogm-white);
                padding: 10px;
                margin-bottom: 10px;
                margin-right: 8px;
                font-family: "Public Sans", sans-serif;
                font-weight: 700;
                text-decoration: none;
            }

            a:hover {
                background-color: var(--ogm-white);
                color: var(--ogm-timberwolves);
            }

            .buttonSection {
                padding-top: 10px;
            }

            .text {
                font-size: 18px;
                color: var(--ogm-white);
            }

            .oops {
                padding-top: 32px;
                font-family: "futura-pt-condensed", sans-serif;
                color: var(--ogm-seahawks);
                font-size: 72px;
            }

            @media only screen and (max-width: 1024px) {
                body {
                    text-align: center;
                    margin: 0px;
                    padding: 0px;
                    width: 100vw;
                }

                .textSection {
                    height: 100%;
                    width: 100%;
                    padding: 12px;
                }

                .text {
                    font-size: 18px;
                }

                .desktopImage {
                    display: none;
                }

                .mobileImage {
                    display: flex;
                    height: 350px;
                    width: 350px;
                    margin: 20px auto;
                }

                .logo {
                    height: 30px;
                }
            }

            @media only screen and (max-width: 600px) {
                .oops {
                    font-size: 48px;
                }

                .text {
                    font-size: 16px;
                }

                .logo {
                    height: 20px;
                }

                .mobileImage {
                    height: 200px;
                    width: 200px;
                }
            }
        `}</style>
			</head>
			<body>
				<div className="textSection">
					<img
            alt="Internal Server Error"
            className="mobileImage"
            src={`${rootLoaderData?.resourcesUrl ?? ''}/assets/img/us-500.png`} />

					<Logo resourcesUrl={rootLoaderData?.resourcesUrl ?? ''} />
					<div className="oops">OOPS!</div>
					<p className="text">
						There was an error while submitting your request. Please try again
						later.
					</p>
					<a className="homepage" href={baseUrl}>
						GO TO HOMEPAGE
					</a>
					<p className="text">Why don't you check out other pages?</p>
					<div className="buttonSection">
						<a href={`${baseUrl}/free-bets`}>FREE BETS</a>
						{/* TODO: the below needs to change for OC repub to TIPS */}
						<a href={`${baseUrl}/picks-parlays`}>PICKS</a>
						<a href={`${baseUrl}/betting-guides`}>BETTING GUIDES</a>
					</div>
				</div>
				<img
          alt="Internal Server Error"
          className="desktopImage"
          src={`${rootLoaderData?.resourcesUrl ?? ''}/assets/img/us-500.png`} />

			</body>
		</html>);

}