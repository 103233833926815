import {
	LiveReload,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
	useRouteLoaderData,
} from '@remix-run/react';
import { ExternalScripts } from 'remix-utils/external-scripts';

import {
	featureFlags,
	useFeatureFlag,
} from './providers/feature-flag-provider';
import { GoogleTagManagerBody } from './scripts/tracking/GoogleTagManagerBody';
import { type loader } from './root-loader';

export default function Body() {
	const loaderData = useRouteLoaderData<typeof loader>('root')!;
	const { pathname } = useLocation();
	const disableGtm = useFeatureFlag(featureFlags.DISABLE_GOOGLE_TAG_MANAGER);
	const disableOfferWidgets = useFeatureFlag(
		featureFlags.DISABLE_OFFER_WIDGETS,
	);

	const { ENV, gtmId } = loaderData;
	const theme = pathname.includes('plus') ? 'plus' : 'oddschecker';

	return (
		<body
			data-fpsm-theme={theme}
			data-template={pathname.split('/').slice(1).join('_')}
		>
			<GoogleTagManagerBody
				gtmId={gtmId}
				shouldRender={
					!disableGtm &&
					(ENV.ENVIRONMENT === 'PROD' ||
						ENV.ENVIRONMENT === 'STG' ||
						ENV.ENVIRONMENT === 'TEST')
				}
			/>
			<Outlet context={loaderData} />
			<script
				dangerouslySetInnerHTML={{
					__html: `window.ENV = ${JSON.stringify(ENV)}`,
				}}
			/>
			<ScrollRestoration />
			{!disableOfferWidgets && <ExternalScripts />}
			<Scripts />
			<LiveReload />
		</body>
	);
}
