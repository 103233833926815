import { type ClientHint, getHintUtils } from '@epic-web/client-hints';
import { clientHint as timeZoneHint } from '@epic-web/client-hints/time-zone';
import { enUS } from 'date-fns/locale';

export const clientHintLocale = {
	cookieName: 'CH-locale',
	getValueCode: 'Intl.DateTimeFormat().resolvedOptions().locale',
	fallback: enUS.code,
} as const satisfies ClientHint<string>;

export const clientHintTimeZone = {
	cookieName: 'CH-time-zone',
	getValueCode: 'Intl.DateTimeFormat().resolvedOptions().timeZone',
	fallback: 'America/New_York',
} as const satisfies ClientHint<string>;

const hintsUtils = getHintUtils({
	locale: clientHintLocale,
	timeZone: timeZoneHint,
});

export const { getHints } = hintsUtils;

export function ClientHintCheck({ nonce }: { nonce: string }) {
	return (
		<script
			dangerouslySetInnerHTML={{
				__html: hintsUtils.getClientHintCheckScript(),
			}}
			nonce={nonce}
		/>
	);
}
